import React from 'react'
import { graphql } from 'gatsby'
import { MDBRow, MDBCol, MDBContainer, MDBAnimation } from 'mdbreact'
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import CardIcons from '../components/cardIcons.js'
import Card from '../components/card.js'
import Cta from '../components/cta'

const SupportTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  return (
    <div id="sub-page">
      <Layout>
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname}
          image={ data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp?.gatsbyImageData?.images ?.fallback?.src }
        />

        <Hero
          class="intro-65"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          type="root"
        />

        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol>
                <div
                  className="font-w-400 text-xs-extra-large divlink text-opening-para"
                  dangerouslySetInnerHTML={{ __html: post.frontmatter.openingpara, }}
                />
                <div
                  className="mt-3 richtext divlink"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        <section className="bg-white zero-top">
          <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <h3 className="font-alt font-w-700 title-xs-medium title-large pb-4">
                {post.frontmatter.howhelp.title}
              </h3>
              {post.frontmatter.howhelp.custservice && (
                <MDBRow className="pt-5">
                  <>
                    {post.frontmatter.howhelp.custservice.map(
                      (custservices, index) => {
                        return (
                          <CardIcons
                            key={index}
                            collg="6"
                            colmd="6"
                            title={custservices.title}
                            description={custservices.description}
                            image={ custservices.image.childImageSharp.gatsbyImageData }
                            alt={custservices.alttext}
                          />
                        )
                      }
                    )}
                  </>
                </MDBRow>
              )}
            </MDBContainer>
          </MDBAnimation>
        </section>
        
        {post.frontmatter.helpfulresources && 
        <section className="bg-white-grey-gradient">
          <MDBAnimation reveal type="fadeIn" duration="1587ms">
            <MDBContainer>
              <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left">
                {post.frontmatter.helpfulresources.title}
              </h2>
              {post.frontmatter.helpfulresources.helpfulres && (
                <MDBRow className="pt-5">
                  <>
                    {post.frontmatter.helpfulresources.helpfulres.map((helpfulres, index) => (
                      <Card
                        key={index}
                        collg="4"
                        colmd="6"
                        height="10rem"
                        title={helpfulres.title}
                        subtitle={helpfulres.subtitle}
                        description={helpfulres.description}
                        image={ helpfulres.image.childImageSharp.gatsbyImageData }
                        alttext={helpfulres.alttext}
                        placement={helpfulres.placement} link={ helpfulres.link ? helpfulres.link : helpfulres.document.publicURL }
                        titleclass="title-small"
                        descriptionClass="text-card-small py-2"
                      />
                    ))}
                  </>
                </MDBRow>
              )}
            </MDBContainer>
          </MDBAnimation>
        </section>
      }         

      {post.frontmatter.cta && (
        <Cta
          ctatitle={post.frontmatter.cta.title}
          ctadescription={post.frontmatter.cta.description}
          ctalink={post.frontmatter.cta.link}
          ctalinktext={post.frontmatter.cta.linktext}
        />
      )}

      </Layout>
    </div>
  )
}
export default SupportTemplate

export const query = graphql`
  query ($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "support" } }
    ) {
      frontmatter {
        meta {
          title
          description
        }
        title
        subtitle
        openingpara
        alttext
        image {
          childImageSharp {
            gatsbyImageData(quality: 90)
          }
        }
        howhelp {
          title
          custservice {
            title
            description
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
          }
        }
        helpfulresources {
          title
          helpfulres {
            title
            subtitle
            description
            placement
            link
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            document {
              publicURL
            }
          }
        }
        cta {
          title
          description
          linktext
          link
        }              
      }
      html
    }
  }
`
